import Dashboard from "../Pages/Dashboard/Dashboard";
import ProjectList from "../Pages/Masters/ProjectList";
import ProjectProfile from "../Pages/Masters/ProjectProfile";
import ManageDocument from '../Components/ManageProject/Document'
import ManageContractorList from "../Components/ManageProject/ContractorList"
import ManageConsultantList from "../Components/ManageProject/ConsultantList"
import ManageGallery from "../Components/ManageProject/Gallery"
import ItemList from "../Pages/Masters/ItemList";
import ManageTask from "../Components/ManageProject/Task";

// routes.js
const routes = [
  ////////////////////// Dashboard Routing ///////////////////////////
  { path: "/", element: <Dashboard /> },

  ////////////////////// Master's Routing ///////////////////////////
  { path: "/ProjectList", element: <ProjectList /> },

  ////////////////////// Master's Products Start ///////////////////////////
  { path: "/ProjectProfile/:project_id?", element: <ProjectProfile /> },
  // { path: "/Documents", element: <ManageDocument /> },
  // { path: "/ContractorList", element: <ManageContractorList /> },
  // { path: "/ConsultantList", element: <ManageConsultantList /> },
  // { path: "/Gallery", element: <ManageGallery /> },


  // Item List 
  { path: "/ItemList/:project_id", element: <ItemList /> },


  
  ////////////////////// Project's Routing ///////////////////////////
  { path: "/ProjectProfile/:project_id?", element: <ProjectProfile /> },
  { path: "/Documents/:project_id?", element: <ManageDocument /> },
  { path: "/ContractorList/:project_id?", element: <ManageContractorList /> },
  { path: "/ConsultantList/:project_id?", element: <ManageConsultantList /> },
  { path: "/Gallery/:project_id?", element: <ManageGallery /> },
  { path : "/Task/:project_id?", element: <ManageTask/>},

];

export default routes;
