import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { useParams } from "react-router-dom";
import { formatDateRange } from "../../Components/GlobalFunctions";

const ProjectProfileHeader = () => {
  const { project_id } = useParams();
  const { apiURL, apiHeaderJson, apiHeaderFile } = useContext(ConfigContext);
  const [profileData, setProfileData] = useState({});

  const GetProfileInfo = async () => {
    try {
      const response = await axios.get(`${apiURL}api/projects/${project_id}`);
      const data = response.data;
      
      if (data.success) {
        setProfileData(data.data);
      } else {
        setProfileData({});
      }
    } catch (error) {
      console.log(error);
    }
  };

  const GetStatus = (status) =>{
    if(status === 0){
      return "Active"
    }
    if(status === 1){
      return "In-Active"
    }
    if(status === 2){
      return "Completed"
    }
  }
  useEffect(() => {
    GetProfileInfo();
  }, []);
  return (
    <>
      <div className="profile-foreground position-relative mx-n4 mt-n4">
        <div className="profile-wid-bg">
          <img
            src="https://admin.atctransline.in/assets/images/architecture.jpg"
            alt
            className="profile-wid-img"
          />
        </div>
      </div>
      <div className="pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper">
        <div className="row g-4">
          <div className="col-auto">
            <div className="avatar-lg">
              <img
                src="https://admin.atctransline.in/assets/images/users/flat-design.jpg"
                alt="user-img"
                className="img-thumbnail rounded-circle"
              />
            </div>
          </div>
          <div className="col">
            <div className="p-2">
              <h3 className="text-white mb-1">
                {profileData?.project_title || "-"}
              </h3>
              <p className="text-white text-opacity-75">
                {profileData?.profile_name|| "-"}{" "}
              </p>
              <div className="hstack text-white-50 gap-1">
                <div className="me-2">
                  <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle" />
                  {profileData?.project_address}
                </div>
                <div>
                  <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle" />
                  DualSysco Research & Development
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="col-12 col-lg-auto order-last order-lg-0">
              <div className="row text text-white-50 text-center">
                <div className="col-lg-12 col-4 ">
                  <div className="d-flex justify-content-between align-items-center flex-nowrap">
                    <div className="p-2">
                      <h4 className="text-white mb-1">{profileData?.total_consultants}</h4>
                      <p className="fs-14 mb-0">Consultant</p>
                    </div>
                    <div className="p-2">
                      <h4 className="text-white mb-1">{profileData?.total_contractors}</h4>
                      <p className="fs-14 mb-0">Contractor</p>
                    </div>
                    <div className="p-2 text-nowrap ml-1 ">
                      <h4 className="text-white mb-1 ">
                        {formatDateRange(
                          profileData?.project_start_date,
                          profileData?.project_end_date
                        )}
                      </h4>
                      <p className="fs-14 mb-0">Duration</p>
                    </div>
                    <div className="p-2">
                      {/* <h4 className="text-white mb-1">{GetStatus(profileData?.status || "Active")}</h4> */}
                      <h4 className="text-white mb-1">{GetStatus(profileData?.project_status)}</h4>
                      <p className="fs-14 mb-0"> Status</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-auto order-last order-lg-0">
            <div className="row text text-white-50 text-center">
              <div className="col-lg-4 col-4"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProjectProfileHeader;
