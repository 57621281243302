import React, { useContext, useEffect, useState } from "react";
import GallerySlider from "./GallerySlider";
import { ConfigContext } from "../../Context/ConfigContext";
import { useParams } from "react-router-dom";
import axios from "axios";
import { ContentLoader, NoRecords } from "../Shimmer";
import Swal from 'sweetalert2'
import { formatDate } from "../GlobalFunctions";

const Gallery = () => {
  const { project_id } = useParams();
  const [isSlider, setisSlider] = useState(false);
  const [galleryData, setGalleryData] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const { apiURL, apiHeaderFile, imageURL ,apiHeaderJson} = useContext(ConfigContext);

  const handleSlider = (isSlid) => {
    setisSlider(isSlid);
  };
  const handleGetGallery = async () => {
    const headers = apiHeaderFile;
    try {
      setLoadingList(true);
      // const response = await axios.get(`https://napp.newlinesgroup.com/tms/api/${project_id}`, headers);
      const response = await axios.get(
        `${apiURL}api/project-media/project/${project_id}`
      );
      const { data, success } = response.data;
      console.log(data, "Data");
      setLoadingList(false);
      if (success) {
        setGalleryData(data);
      }
    } catch (error) {
      setLoadingList(false);
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const { value: formValues } = await Swal.fire({
      title: "Upload File",
      html: `
            <div class="container">
            <div class="row">
            <div class="col-12 mt-3">
            <div class="row">
                    <label>Document Type:</label><br>
                    <div class="col-6">
                        <input type="radio" id="pmTypeImage" name="pmType" class="form-check-input" value="IMAGE" />
                        <label class="form-check-label" style="font-size:13px;"  for="pmTypeImage">IMAGE</label>
                    </div>
                    <div class="col-6">
                        <input type="radio" id="pmTypeDocument" name="pmType" class="form-check-input" value="DOCUMENT" />
                        <label class="form-check-label" style="font-size:13px;"  for="pmTypeDocument">PDF</label>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
            <input type="text" id="pmTitle" class="form-control" placeholder=" Title" />
            </div>
                <div class="col-12 mt-3">
                    <input type="text" id="pmGeotag" class="form-control" placeholder="Geotag (lat,long)" />
                </div>
                <div class="col-12 mt-3">
                <input type="file" id="fileInput" class="form-control" accept="application/pdf,image/*" />
                </div>
                </div>
                </div>
            `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      preConfirm: () => {
        const fileInput = document.getElementById("fileInput");
        const pmTitle = document.getElementById("pmTitle").value.trim();
        const pmGeotag = document.getElementById("pmGeotag").value.trim();

        const file = fileInput.files[0];
        if (!file) {
          Swal.showValidationMessage("Please select a file");
          return false;
        }
        if (file.size > 10 * 1024 * 1024) {
          Swal.showValidationMessage("File size must be less than 10 MB");
          return false;
        }

        // Check file type
        const pmType = document.querySelector('input[name="pmType"]:checked');
        if (!pmType) {
          Swal.showValidationMessage("Please select a document type");
          return false;
        }

        // Validate file type based on selected document type
        const validImageTypes = ["image/jpeg", "image/png", "image/jpg"];
        const validPdfType = "application/pdf";

        if (pmType.value === "IMAGE" && !validImageTypes.includes(file.type)) {
          Swal.showValidationMessage("Please upload a valid image (JPEG/PNG)");
          return false;
        }

        if (pmType.value === "DOCUMENT" && file.type !== validPdfType) {
          Swal.showValidationMessage("Please upload a valid PDF");
          return false;
        }

        // Validate title with regex
        const titleRegex = /^[a-zA-Z0-9][a-zA-Z0-9 _-]*[a-zA-Z0-9]$/;
        if (!pmTitle) {
          Swal.showValidationMessage("Please enter the title");
          return false;
        }
        if (!titleRegex.test(pmTitle)) {
          Swal.showValidationMessage(
            "Invalid title. Title must start and end with an alphanumeric character and can include spaces, underscores, and dashes."
          );
          return false;
        }

        // Validate geotag with regex
        const geotagRegex =
          /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d|[1-9]?\d)(\.\d+)?|180(\.0+)?)$/;
        if (!pmGeotag) {
          Swal.showValidationMessage("Please enter geo tag");
          return false;
        }
        if (!geotagRegex.test(pmGeotag)) {
          Swal.showValidationMessage(
            "Invalid geotag. Format: latitude,longitude (e.g., 12.34,56.78)."
          );
          return false;
        }

        return { file, pmTitle, pmType: pmType.value, pmGeotag };
      },
    });

    if (!formValues) return;

    try {
      const headers = apiHeaderFile;
      const formData = new FormData();
      formData.append("file", formValues.file);
      formData.append("pm_project_id", project_id);
      formData.append("pm_title", formValues.pmTitle);
      formData.append("pm_type", formValues.pmType);
      formData.append("pm_geotag", formValues.pmGeotag);
      formData.append("created_by", localStorage.getItem("user_id"));
      formData.append("created_date", formatDate());

      const url = `${apiURL}api/project-media`;

      const response = await axios.post(url, formData, { headers });
      const { success, message, data } = response.data;

      Swal.fire({
        title: `<strong>${success ? "Success" : "Failed"}</strong>`,
        text: "Media Uploaded Successfully",
        icon: success ? "success" : "error",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
      });

      if (success) {
        setGalleryData((prevData) => [...prevData, data]);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: "Error",
        text: "An error occurred while uploading the file. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };
  
  useEffect(() => {
    handleGetGallery();
  }, []);
  return (
    <div className="col-xxl-12">
      <div className="card">
        <div className="row">
          <div className="col-lg-12">
            <div className="card-body">
              <div className="card-header d-flex align-items-center mb-4">
                <h5 className="card-title flex-grow-1 mb-0">Gallery</h5>
                <div className="flex-shrink-0">
                  <button
                    type="button"
                    htmlFor="formFile"
                    className="btn btn-warning"
                    onClick={() => handleSubmit()}
                  >
                    <i className="ri-upload-2-fill me-1 align-bottom" /> Upload
                    Images
                  </button>
                </div>
              </div>

              {isSlider === false ? (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row gallery-wrapper">
                      {/* {Array.from({ length: 6 }).map((item, index) => ( */}
                      {loadingList ? (
                        <ContentLoader />
                      ) : galleryData.length === 0 ? (
                        <NoRecords />
                      ) : (
                        galleryData.map((item) => {
                          return (
                            item.pm_type === "IMAGE" && (
                              <div
                                className="element-item col-xxl-3 col-xl-4 col-sm-6 project designing development"
                                key={item.project_media_id}
                              >
                                <div className="gallery-box card">
                                  <div className="gallery-container">
                                    <a
                                      className="image-popup"
                                      onClick={() => handleSlider(true)}
                                      title=""
                                    >
                                      <img
                                        className="gallery-img img-fluid mx-auto"
                                        // src={`https://admin.atctransline.in/assets/images/Constraction/${index + 1}.jpg`}
                                        src={imageURL + item.pm_file_name}
                                        alt=""
                                      />

                                      <div className="gallery-overlay">
                                       
                                        {
                                          //   <h5 className="overlay-caption">
                                          //     Construction Site Picture {index + 1}
                                          //   </h5>
                                        }
                                      </div>
                                    </a>
                                  </div>
                                  <div className="box-content">
                                    <div className="d-flex align-items-center mt-1">
                                      <div className="flex-grow-1 text-muted">
                                        by{" "}
                                        <a
                                          href=""
                                          className="text-body text-truncate"
                                        >
                                          Dualsysco R&D
                                        </a>
                                      </div>
                                      <div className="flex-shrink-0"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          );
                        })
                      )}
                      {/* ))} */}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <GallerySlider />
                  {isSlider && (
                    <div className="d-flex justify-content-center align-items-center">
                      <button
                        className="btn btn-primary"
                        onClick={() => handleSlider(false)}
                      >
                        Go Back
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
