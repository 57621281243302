import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import ApexCharts from "apexcharts";
import { formatNumber } from "../../Components/GlobalFunctions"; 

const StatusChart = () => {
  const [chartData, setChartData] = useState(null);
  const [info, setInfo] = useState([]);
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);

  const getChart = async () => {
    try 
    {
      const response = await axios.get(`${apiURL}DashBoard/GetChart`, {
        headers: apiHeaderJson,
      });
      if (response.data.success) {
        setChartData(response.data.options);
        setInfo(response.data.totals);
      }
    } catch (error) {
      console.error("Error status charts:", error);
    }
  };

  useEffect(() => {
    // getChart();
  }, []); // Fetch data on component mount

  useEffect(() => {
    if (chartData) {
      const options = {
        series: [
          {
            name: "Ongoing Projects",
            type: "area",
            // data: chartData.orders,
            data:[1,2,3,4,5,6,7,6,5,4,3,5]
          },
          {
            name: "Completed Projects",
            type: "bar",
            // data: chartData.earnings.map(formatNumber), 
            data:[10, 15, 13, 14, 18, 19, 16, 17, 15, 14, 13, 12]
          },
          {
            name: "Pending Projects",
            type: "line",
            // data: chartData.refunds,
            data: [2, 4, 7, 4, 5,6, 6,4, 9, 8, 5, 7],
          },
        ],
        chart: {
          height: 370,
          type: "line",
          toolbar: { show: false },
        },
        stroke: {
          curve: "straight",
          dashArray: [0, 0, 8],
          width: [2, 0, 2.2],
        },
        fill: {
          opacity: [0.1, 0.9, 1],
        },
        markers: {
          size: [0, 0, 0],
          strokeWidth: 2,
          hover: { size: 4 },
        },
        xaxis: {
          categories: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
          ],
          axisTicks: { show: false },
          axisBorder: { show: false },
        },
        grid: {
          show: true,
          xaxis: { lines: { show: true } },
          yaxis: { lines: { show: false } },
          padding: { top: 0, right: -2, bottom: 15, left: 10 },
        },
        legend: {
          show: true,
          horizontalAlign: "center",
          offsetX: 0,
          offsetY: -5,
          markers: { width: 9, height: 9, radius: 6 },
          itemMargin: { horizontal: 10, vertical: 0 },
        },
        plotOptions: {
          bar: { columnWidth: "30%", barHeight: "70%" },
        },
        colors: ["#5cb85c", "#17a2b0", "#ed5e5e"],
        tooltip: {
          shared: true,
          y: [
            {
              formatter: (e) => e?.toFixed(0) || e,
            },
            {
              formatter: (e) => (e ? `${formatNumber(e)}` : e), // Apply formatNumber in tooltip for earnings
            },
            {
              formatter: (e) => (e ? `${e.toFixed(0)} ` : e),
            },
          ],
        },
      };

      const chart = new ApexCharts(
        document.querySelector("#customer_impression_charts"),
        options
      );
      chart.render();

      return () => {
        chart.destroy(); // Clean up on component unmount
      };
    }
  }, [chartData]); // Update chart when chartData changes

  return (
    <>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-header border-0 align-items-center d-flex">
              <h4 className="card-title mb-0 flex-grow-1">Projects Statistics</h4>
              <div>
                <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                  ALL
                </button>
                <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                  1M
                </button>
                <button type="button" className="btn btn-soft-dark btn-sm mx-1">
                  6M
                </button>
                <button
                  type="button"
                  className="btn btn-soft-primary btn-sm mx-1"
                >
                  1Y
                </button>
              </div>
            </div>
            <div className="card-header p-0 border-0 bg-light-subtle">
              <div className="row g-0 text-center">
              <div className="col-6 col-sm-4">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target={367}>
                        {/* {formatNumber(info.totalRefundsSum)} Format total refunds */}
                        48
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Completed Projects</p>
                  </div>
                </div>
              
                <div className="col-6 col-sm-4">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target={367}>
                        {/* {formatNumber(info.totalRefundsSum)} Format total refunds */}
                        22
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Ongoing Projects</p>
                  </div>
                </div>
                <div className="col-6 col-sm-4">
                  <div className="p-3 border border-dashed border-start-0">
                    <h5 className="mb-1">
                      <span className="counter-value" data-target={7585}>
                        {/* {formatNumber(info.totalOrdersSum)} Format total orders */}
                        20
                      </span>
                    </h5>
                    <p className="text-muted mb-0">Pending Projects</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body p-0 pb-2">
        <div className="w-100">
          <div
            id="customer_impression_charts"
            className="apex-charts"
            dir="ltr"
          />
        </div>
      </div>
    </>
  );
};

export default StatusChart;
