import React, { useState, useEffect, useContext, useCallback } from "react";
import PageTitle from "../../Components/PageTitle";
import axios from "axios";
import Swal from "sweetalert2";
import { ConfigContext } from "../../Context/ConfigContext";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import Select from "react-select";
import { NavLink, useParams } from "react-router-dom";

const ItemList = ({ customStyles }) => {
    const { apiURL, apiHeaderJson, apiHeaderFile, user_profile_id } = useContext(ConfigContext);
    const [rows, setRows] = useState([]);
    const [ItemRows, setItemRows] = useState([]);
    const [next, setNext] = useState(false);
    const [prev, setPrev] = useState(false);
    const [page, setPage] = useState(1);
    const [limit] = useState(10);
    const [totalRecords, setTotalRecords] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [loadingList, setLoadingList] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [price, setPrice] = useState("");
    const [quantity, setQuantity] = useState("");
    const [reload, setReload] = useState(false)
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [selectOption, setSelectOption] = useState(null);
    const [status, setStatus] = useState(null);
    const [item_row, setItemRow] = useState([]);
    const [item_id, setItemId] = useState(0)
    const [itemRemark, setItemRemark] = useState("");

    const { project_id } = useParams()


    const options = [
        { value: 'pending', label: "Pending" },
        { value: 'approved', label: 'Approved' },
        { value: 'rejected', label: 'Rejected' }
    ];


    const AcceptRejectRequest = (actionType) => {
        Swal.fire({
            title: `Submit Remark for ${actionType}`,
            input: 'text',
            inputPlaceholder: 'Enter your Remark',
            showCancelButton: true,
            confirmButtonText: 'Submit',
            cancelButtonText: 'Cancel',
            inputValidator: (value) => {
                if (!value) {
                    return 'Remark cannot be empty';
                }
            },
        }).then((result) => {
            if (result.isConfirmed) {
                // Show success message
                Swal.fire({
                    icon: 'success',
                    title: 'Remark successfully submitted',
                    showConfirmButton: false,
                    timer: 1500, // Auto-close after 1.5 seconds
                }).then(() => {
                    // Update state based on action
                    if (actionType === 'Accept') {
                        setStatus('approved');
                    } else if (actionType === 'Cancel') {
                        setStatus('rejected');
                    }
                });
            }
        });
    };


    const handleApprove = () => {
        Swal.fire({
            icon: 'success',
            title: 'Request Approved Successfully',
            showConfirmButton: false,
            timer: 1500
        });

        // Update status to approved
        setStatus('approved');
    };

    useEffect(() => {
        
        GetList();
    }, [page]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "price") {
            setPrice(value);
        } else if (name === "quantity") {
            setQuantity(value);
        }
        else if (name === "itemRemark") {
            setItemRemark(value);

        }
        else if (name === "keyword") {
            setKeyword(value);
        }
    };

    const handleFilter = () => {
        // setPage(1);
        setReload(true);
    }

    const GetList = async () => {
        const headers = apiHeaderJson;
        const response = await axios
            .get(`${apiURL}api/contractors/Get-Request-Items`, {
                params: { contractor_id: user_profile_id, project_id: project_id },
                headers,
            })
        const data = response.data;

        if (data.success) {
            setRows(data.data);
            setNext(data.next);
            setPrev(data.prev);
            // setPage(data.page);
            setTotalRecords(data.total_records);
            setTotalPages(data.total_pages);
            setReload(false);
        }
        setLoadingList(false);
    };

    const getItemsList = async () => {
        const headers = apiHeaderJson;
        try {
            const response = await axios.get(`${apiURL}api/items`)
            const { data, success } = response.data;

            if (success) {
                setItemRow(
                    data.map((row) => ({
                        value: row.item_id,
                        label: row.item_name,
                    }))
                );
            }
            else {
                setItemRow([])
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();


        const headers = apiHeaderFile;
        const Body = {
            project_id: project_id,
            contractor_id: user_profile_id,
            items: JSON.stringify(ItemRows),
        };

        const response = await axios
            .post(`${apiURL}api/contractors/Request-Items`, Body)

        const { success } = response.data;

        Swal.fire({
            title: `<strong>${success ? "Success" : "Failed"}</strong>`,
            text: response.data.message,
            icon: success ? "success" : "error",
            timer: 2000,
            timerProgressBar: true,
            showConfirmButton: false,
        });

        if (success) {
            resetForm();
            setLoadingList(true);
            GetList();
        } else {
            setLoadingList(true);
            GetList();
        }
    };

   
    const AddItems = () => {
        if (item_id && quantity && price && itemRemark) {
            let row = {
                item_id: item_id,
                item_name: selectedOptions ? selectedOptions.label : "",
                item_qty: quantity,
                item_price: price,
                remark: itemRemark,
            };
            setItemRows((prevItems) => [...prevItems, row]);
            setItemId(null);
            setQuantity('');
            setPrice('');
            setSelectedOptions(null);
            setItemRemark('');
        }
    };

    const deleteItem = (index) => {
        setItemRows((prevItems) => prevItems.filter((_, i) => i !== index));
    };


    const handlePrev = () => {
        if (prev) {
            setPage((prevPage) => prevPage - 1);
        }
    };

    const handleNext = () => {
        if (next) {
            setPage((prevPage) => prevPage + 1);
        }
    };

    const handleChange = (e) => {
        setPage(parseInt(e.target.value, 10));
    };

    const resetForm = () => {
        setItemRows("");
    };


    const handleClear = () => {
        setKeyword("")
        setReload(true);
    }



    // Handler for when the user selects options

    const handleSelect = (selectedOption) => {
        setItemId(selectedOption ? selectedOption.value : null);
        setSelectedOptions(selectedOption);
    };
    const Labels = (status) => {
        if (status === 0) {
            return "Pending"
        }
        else if (status === 1) {
            return "Approved"
        }
        else if (status === 2) {
            return "Rejected"
        }
    }
    const Classes = (status) => {
        if (status === 0) {
            return "badge bg-warning-subtle text-warning"
        }
        else if (status === 1) {
            return "badge bg-success-subtle text-success"
        }
        else if (status === 2) {
            return "badge bg-danger-subtle text-danger"
        }
    }



    const handleSelectStatus = (selectedOption) => {
        setSelectOption(selectedOption ? selectedOption.value : '');
    };

    useEffect(() => {
        getItemsList();
        reload && GetList()
    }, [reload])

    return (
        <div className="main-content">
            <div className="page-content">
                {loadingList === false ? (
                    <div className="container-fluid">
                        <br />
                        <PageTitle title={`Add Items`} primary={`Home`} />
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div
                                        className='card-header align-items-center d-flex'
                                        style={{
                                            background: "#257180",
                                        }}>
                                        <h4 className='card-title mb-0 flex-grow-1 text-white'>Add New Items</h4>

                                    </div>
                                    <div className="card-body">
                                        <div className="row gy-4">
                                            <div className="col-md-12">
                                                <form onSubmit={handleSubmit} className="row">
                                                    <div className="col-md-4 mt-3">
                                                        <Select
                                                            onChange={handleSelect}
                                                            options={item_row}
                                                            placeholder=" Select Item"
                                                            id="setItemError"
                                                            value={
                                                                item_id
                                                                    ? item_row.find((option) => option.value === item_id)
                                                                    : null
                                                            }
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <input
                                                            type="number"
                                                            id="quantity"
                                                            name="quantity"
                                                            value={quantity}
                                                            onChange={handleInputChange}
                                                            className={`form-control `}
                                                            placeholder=" Enter Quantity"
                                                        />
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <input
                                                            type="number"
                                                            id="price"
                                                            name="price"
                                                            value={price}
                                                            onChange={handleInputChange}
                                                            className={`form-control `}
                                                            placeholder=" Enter Price   "
                                                        />
                                                    </div>
                                                    <div className="col-md-8 mt-3">
                                                        <textarea
                                                            id="itemRemark"
                                                            name="itemRemark"
                                                            value={itemRemark}
                                                            onChange={handleInputChange}
                                                            className={`form-control `}
                                                            placeholder=" Please Enter Remark   "
                                                        />
                                                    </div>

                                                    <div className="col-md-2 " style={{ marginTop: '16px' }}>
                                                        <button className="btn btn-success btn-label waves-effect right waves-light" type="button" onClick={AddItems}>Add <i className="bx bx-add-to-queue label-icon align-middle fs-16 ms-2"></i> </button>
                                                    </div>
                                                    {ItemRows.length > 0 && <div className="col-md-12 mt-3">

                                                        <table className="table table-nowrap">
                                                            <thead className="table-light ">
                                                                <tr>

                                                                    <th width="20">Items Name</th>
                                                                    <th width="10">Quantity</th>
                                                                    <th width="10">Price</th>
                                                                    <th width="50">Remark</th>
                                                                    <th width="10%">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {ItemRows.map((item, i) => (
                                                                    <tr key={i}>
                                                                        <td>{item.item_name}</td>
                                                                        <td>{item.item_qty}</td>
                                                                        <td>{item.item_price}</td>
                                                                        <td>{item.remark}</td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-link text-danger fs-15 p-0"
                                                                                onClick={() => deleteItem(i)}
                                                                            >
                                                                                <i className="ri-delete-bin-line" />
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>

                                                        </table>
                                                    </div>}
                                                    <div className="col-md-6 mt-3">


                                                    </div>

                                                    <div
                                                        className="col-md-12"
                                                        style={{ marginTop: "30px" }}
                                                    >
                                                        <button className="btn btn-primary btn-label waves-effect right waves-light" type="button" disabled={ItemRows.length === 0} onClick={handleSubmit}>Save <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i> </button>

                                                    </div>
                                                </form>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div className="col-md-6">
                                <div className="card">
                                <div
                                        className='card-header align-items-center d-flex'
                                        style={{
                                            background: "#257180",
                                        }}>
                                        <h4 className='card-title mb-0 flex-grow-1 text-white'>Requested List</h4>


                                        <div className='avatar-xs  flex-shrink-0'>
                                            <NavLink
                                                // to={`/Projects/${projectId}`}
                                                to={'/ProjectList'}
                                                className='avatar-title bg-white rounded-circle fs-4'
                                                style={{
                                                    color: "#184c8f",
                                                }}>
                                                <i className='la la-list'></i>
                                            </NavLink>
                                        </div>
                                    </div>
                                    <div className="card-header pt-0 pb-0" style={{ border: "none" }}>
                                        <div className="row pb-2  py-4 mb-3" >
                                            <div className="col-md-7">
                                                <Select
                                                    options={options} // Pass the options array
                                                    value={selectOption ? options.find(option => option.value === selectOption) : null}
                                                    onChange={handleSelectStatus}
                                                    styles={customStyles}
                                                    placeholder="Please Select Status"
                                                />
                                            </div>

                                            <div className="col-md-5">

                                                <div className="dropdown">
                                                    <button type="button" className="btn btn-light btn-label waves-effect waves-light " id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="true"
                                                    >
                                                        <i className="ri-download-2-line label-icon align-middle fs-16 me-2" />
                                                        Download Report
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-printer-line me-2 align-middle text-muted" />Print</a>
                                                        </li>
                                                        <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-pdf-line me-2 align-middle text-muted" />PDF</a>
                                                            <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-excel-2-line me-2 align-middle text-muted" />Excel</a>
                                                            </li>

                                                        </li>
                                                    </ul>


                                                </div>

                                            </div>

                                        </div>


                                    </div>
                                    <div className="col-md-12">
                                        <div className="card-body pt-0">
                                            <table
                                                className={`table table-bordered table-stripped `}
                                            >
                                                <thead className="table-light">
                                                    <tr>
                                                        <th className="text-center" width="28%">Item Name</th>
                                                        <th className="text-center" width="18%">Quantity</th>
                                                        <th className="text-center" width="18%">Price(QAR)</th>
                                                        <th className="text-center" width="15%">Status</th>
                                                        <th className="text-center" width="15%">
                                                            Action
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {rows.length !== 0 ? (
                                                    <tbody>
                                                        {project_id &&
                                                            rows.map((row) => (
                                                                <tr key={row.category_id}>
                                                                    <td className="text-center">{row?.pi_item_title ?? '-'}</td>
                                                                    <td className="text-center">{row?.pi_item_qty}</td>
                                                                    <td className="text-center">{row?.pi_item_price}</td>
                                                                    <td className="text-center"><span className={Classes(row.pi_status)}>{Labels(row.pi_status)}</span></td>
                                                                    {/* <td className="text-center">Architecture</td> */}

                                                                    <td className="d-flex justify-content-around">


                                                                        {/* Reject Button */}
                                                                        {row.pi_status === 0 && (
                                                                            <button
                                                                                onClick={() => AcceptRejectRequest('Cancel')}
                                                                                type="button"
                                                                                className="btn btn-outline-secondary btn-sm btn-icon waves-effect waves-light"
                                                                            >
                                                                                <i className="  ri-close-line align-middle fs-15 "></i>
                                                                            </button>
                                                                        )}
                                                                    </td>

                                                                </tr>
                                                            ))}
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan="5">
                                                                <NoRecords />
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                )}
                                                <tfoot className="table-light">
                                                    <tr>
                                                        <th colSpan={6}>
                                                            <div className="d-flex justify-content-between">
                                                                <button
                                                                    disabled={
                                                                        prev === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handlePrev()}
                                                                    className={`btn btn-warning btn-label waves-effect waves-light`}
                                                                >
                                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />{" "}
                                                                    Previous
                                                                </button>
                                                                <div
                                                                    className="col-md-4"
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    {
                                                                        <small>
                                                                            Total Records: {totalRecords} | Total
                                                                            Pages: {totalPages} | Current Page:{" "}
                                                                            {page}
                                                                        </small>
                                                                    }
                                                                </div>
                                                                <div className="col-md-2">
                                                                    <select
                                                                        className="form-select"
                                                                        onChange={(e) => handleChange(e)}
                                                                    >
                                                                        {Array.from(
                                                                            { length: totalPages },
                                                                            (_, i) => (
                                                                                <option
                                                                                    key={i + 1}
                                                                                    selected={page === i + 1}
                                                                                    value={i + 1}
                                                                                >
                                                                                    {i + 1}
                                                                                </option>
                                                                            )
                                                                        )}
                                                                    </select>
                                                                </div>
                                                                <button
                                                                    disabled={
                                                                        next === false && loadingList === false
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    type="button"
                                                                    onClick={() => handleNext()}
                                                                    className={`btn btn-primary btn-label waves-effect right waves-light`}
                                                                >
                                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />{" "}
                                                                    Next
                                                                </button>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </tfoot>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                ) : (
                    <ContentLoader />
                )}
            </div>
        </div>
    );
};

export default ItemList;
