import React, { useState, useEffect, useContext } from "react";
import PageTitle from "../../Components/PageTitle";
import axios from "axios";
import Swal from "sweetalert2";
import { ConfigContext } from "../../Context/ConfigContext";
import { ContentLoader, NoRecords } from "../../Components/Shimmer";
import Select from "react-select";
import { NavLink } from "react-router-dom";
import 'flatpickr/dist/themes/material_blue.css';
const ProjectList = () => {

  const { apiURL, apiHeaderJson, user_profile_id } = useContext(ConfigContext);
  const [rows, setRows] = useState([]);
  const [next, setNext] = useState(false);
  const [prev, setPrev] = useState(false);
  const [page, setPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [loadingList, setLoadingList] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [reload, setReload] = useState(false)
  const [selectOption, setSelectOption] = useState(null);
  const [projectID, setProjectID] = useState(0);

  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "keyword") {
      setKeyword(value);
    }
  };

  const handleFilter = () => {
    // setPage(1);
    setReload(true);
  }

  const GetList = async () => {
    const headers = apiHeaderJson;
    const response = await axios
      .get(`${apiURL}api/contractors/projects/${user_profile_id}`)
    const data = response.data;
    
    if (data.success) {
      setRows(data.data);
      setNext(data.next);
      setPrev(data.prev);
      // setPage(data.page);
      setTotalRecords(data.total_records);
      setTotalPages(data.total_pages);
      setReload(false);
    }
    setLoadingList(false);
  };

  const handlePrev = () => {
    if (prev) {
      setPage((prevPage) => prevPage - 1);
    }
  };

  const handleNext = () => {
    if (next) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleChange = (e) => {
    setPage(parseInt(e.target.value, 10));
  };


  const handleClear = () => {
    setKeyword("")
    setReload(true);
  }

  const handleExcelDownload = () => {
    const headers = apiHeaderJson;
    Swal.fire({
      title: "Please wait...",
      html: "Your file is being prepared for download.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

  };
  const options = [
    { value: 'completed', label: 'Completed' },
    { value: 'pending', label: 'Pending' },
    { value: 'assigned', label: "Assigned" }
  ];
  const optionItem = [
    { value: 'cement', label: 'Cement' },
    { value: 'bricks', label: 'Bricks' },
    { value: 'steel', label: "Steel" }
  ];

  const handleSelectStatus = (selectedOption) => {
    setSelectOption(selectedOption ? selectedOption.value : '');
  };

  const ProjectStatus = (status) =>{
    if(status === 0){
      return "Active"
    }
    if(status === 1){
      return "In-Active"
    }
    if(status === 2){
      return "Completed"
    }
  }
  const ProjectBadge = (status) =>{
    if(status === 0){
     return "badge bg-info-subtle text-info "
    } 
    if(status === 1){
      return "badge bg-warning-subtle text-warning "
    }
    if(status === 2){
      return "badge bg-success-subtle text-success "
    }
  }
  useEffect(() => {
    reload && GetList()
  }, [reload])
  
  useEffect(() => {
    setLoadingList(true);
    GetList();
  }, [page]);

  return (
    <div className="main-content">
      <div className="page-content">
        {loadingList === false ? (
          <div className="container-fluid">
            <br />
            <PageTitle title={`ProjectList`} primary={`Home`} />
            <div className="row">
              <div className="col-md-12">
                <div className="card">

                  <div
                    className='card-header align-items-center d-flex'
                    style={{
                      background: "#257180",
                    }}>
                    <h4 className='card-title mb-0 flex-grow-1 text-white'>Project List</h4>

                  </div>
                  <div className="card-header pt-0 pb-0" style={{ border: "none" }}>
                    <div className="row py-4 pb-2 mb-3">
                      <div className="col-md-3">

                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            name="keyword"
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            placeholder="Please Enter Keyword"
                            value={keyword}
                            onChange={handleInputChange}
                          />

                          <span
                            className='mdi mdi-magnify search-widget-icon'
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              fontSize: "3.5vh",
                              color: "#B1B1B6",
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <Select
                          options={options} // Pass the options array
                          value={selectOption ? options.find(option => option.value === selectOption) : null}
                          onChange={handleSelectStatus}

                          placeholder="Please Select Status"
                        />
                      </div>
                      <div className="col-md-3">



                      </div>

                      <div className="col-md-3">
                        <div className="row">
                          <div className="col-md-3">


                          </div>
                          <div className="col-md-9">

                            <div className="dropdown">
                              <button type="button" className="btn btn-light btn-label waves-effect waves-light " id="dropdownMenuLink4" data-bs-toggle="dropdown" aria-expanded="true"
                              >
                                <i className="ri-download-2-line label-icon align-middle fs-16 me-2" />
                                Download Report
                              </button>
                              <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuLink4">
                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-printer-line me-2 align-middle text-muted" />Print</a>
                                </li>
                                <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-pdf-line me-2 align-middle text-muted" />PDF</a>
                                  <li><a className="dropdown-item" href="javascript:void(0);"><i className="ri-file-excel-2-line me-2 align-middle text-muted" />Excel</a>
                                  </li>

                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pt-0">
                    <table
                      className={`table table-bordered table-stripped`}
                    >
                      <thead className="table-light">
                        <tr>
                          <th className="text-center">Project Name</th>
                          <th className="text-center">Location</th>
                          <th className="text-center">Consultant Name</th>
                          <th className="text-center">Contractor Name</th>
                          <th className="text-center">Project Status</th>
                          <th className="text-center" width={120}>
                            Action
                          </th>
                        </tr>
                      </thead>
                      {rows.length !== 0 ? (
                        <tbody>
                          {rows.map((row) => (
                            <tr key={row.project_id}>
                              <td className="text-center">
                                <NavLink to={`/ProjectProfile/${row.project_id}`}>{row?.project_title}</NavLink>
                              </td>
                              <td className="text-center">{row.project_address}</td>
                              <td className="text-center">
                                <div className="tooltip-container">
                                  <div className="ellipsis-text">Addison George, Céline Antoine</div>
                                  <span className="tooltip-content">Addison George, Céline Antoine</span>
                                </div>
                              </td>
                              <td className="text-center">
                                <div className="tooltip-container">
                                  <div className="ellipsis-text">John Doe, Louis Bans</div>
                                  <span className="tooltip-content">John Doe, Louis Bans</span>
                                </div>
                              </td>
                              <td className="text-center"><span class={ProjectBadge(row.project_status)}>{ProjectStatus(row.project_status) }</span></td>
                              <td className="d-flex justify-content-around">
                                <NavLink to={`/ItemList/${row.project_id}`}>
                                  <button
                                    type="button"
                                    className="btn-icon btn btn-sm btn-outline-primary "

                                  >
                                    <i className="mdi mdi-note-plus-outline"></i>
                                  </button>
                                </NavLink>

                              </td>
                            </tr>
                          ))}
                        </tbody>

                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="4">
                              <NoRecords />
                            </td>
                          </tr>
                        </tbody>
                      )}
                      <tfoot className="table-light">
                        <tr>
                          <th colSpan={6}>
                            <div className="d-flex justify-content-between">
                              <button
                                disabled={
                                  prev === false && loadingList === false
                                    ? true
                                    : false
                                }
                                type="button"
                                onClick={() => handlePrev()}
                                className={`btn btn-warning btn-label waves-effect waves-light`}
                              >
                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2" />{" "}
                                Previous
                              </button>
                              <div
                                className="col-md-4"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                {
                                  <small>
                                    Total Records: {totalRecords} | Total
                                    Pages: {totalPages} | Current Page:{" "}
                                    {page}
                                  </small>
                                }
                              </div>
                              <div className="col-md-2">
                                <select
                                  className="form-select"
                                  onChange={(e) => handleChange(e)}
                                >
                                  {Array.from(
                                    { length: totalPages },
                                    (_, i) => (
                                      <option
                                        key={i + 1}
                                        selected={page === i + 1}
                                        value={i + 1}
                                      >
                                        {i + 1}
                                      </option>
                                    )
                                  )}
                                </select>
                              </div>
                              <button
                                disabled={
                                  next === false && loadingList === false
                                    ? true
                                    : false
                                }
                                type="button"
                                onClick={() => handleNext()}
                                className={`btn btn-primary btn-label waves-effect right waves-light`}
                              >
                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2" />{" "}
                                Next
                              </button>
                            </div>
                          </th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ContentLoader />
        )}
      </div>
    </div >
  );
};

export default ProjectList