import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import StatusChart from "./StatusChart";
import DonutChart from "./DonutCharts";



const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const { apiURL, apiHeaderJson,user_profile_id,user_id } = useContext(ConfigContext);
console.log("User_id",user_id);
console.log("profile_id",user_profile_id);



  const getOrderStatus = async () => {
    const headers = apiHeaderJson;
    const response = await axios.get(`${apiURL}Dashboard/GetAllOrdersStatus `, { headers });
    setOrders(response.data.data);

  }
  useEffect(() => {
    // getOrderStatus()
  }, [])
  const today = new Date();
  return (
    <div className="main-content">
      <div className="page-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="h-100">
                <div className="row mb-3 pb-1">
                  <div className="col-12">
                    <div className="d-flex align-items-lg-center flex-lg-row flex-column">
                      <div className="flex-grow-1">
                        <h4 className="fs-16 mb-1">Good Morning, Contractor !</h4>
                        <p className="text-muted mb-0">
                          Here's what's happening with your store today.
                        </p>
                      </div>
                      <div className="mt-3 mt-lg-0">
                        <form action="javascript:void(0);">
                          <div className="row g-3 mb-0 align-items-center">

                            <div className="col-auto">
                              <NavLink
                                to={"/ProjectList"}
                                type="button"
                                className="btn btn-soft-success"
                              >
                                <i className="ri-add-circle-line align-middle me-1" />
                                Add New Request
                              </NavLink>
                            </div>
                            {/*end col*/}
                            {/* <div className="col-auto">
                              <button
                                type="button"
                                className="btn btn-soft-info btn-icon waves-effect waves-light layout-rightside-btn"
                              >
                                <i className="ri-pulse-line" />
                              </button>
                            </div> */}
                            {/*end col*/}
                          </div>
                          {/*end row*/}
                        </form>
                      </div>

                    </div>
                    {/* end card header */}
                  </div>
                  {/*end col*/}
                </div>
                {/*end row*/}
                <div className="row">
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Completed Projects
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-success fs-14 mb-0">
                              <i className="ri-arrow-right-up-line fs-13 align-middle" />
                              +16.24 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              48
                              <span
                                className="counter-value"
                                data-target="559.25"
                              >
                                {/* {orders.total_orders} */}
                              </span>

                            </h4>
                            <Link
                              // to={`/TotalOrders`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Completed Projects
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-success-subtle rounded fs-3">
                              <i className="  ri-checkbox-circle-line text-success" />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Ongoing Projects
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-danger fs-14 mb-0">
                              <i className="ri-arrow-right-down-line fs-13 align-middle" />
                              -3.57 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target={36894}

                              >
                                22
                                {/* {orders.pending_orders} */}
                              </span>
                            </h4>
                            <Link
                              // to={`/TotalOrders/${0}`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Ongoing Projects
                            </Link>
                          </div>
                          <div className=" avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-info-subtle rounded fs-3">
                              <i
                                className=" ri-building-2-line text-info"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Assigned Projects
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-success fs-14 mb-0">
                              <i className="ri-arrow-right-up-line fs-13 align-middle" />
                              +29.08 %
                            </h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                              <span
                                className="counter-value"
                                data-target="183.35"

                              >
                                5
                                {/* {orders.delivered_orders} */}
                              </span>

                            </h4>
                            <Link
                              // to={`/TotalOrders/${2}`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Assigned Projects
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-warning-subtle rounded fs-3">
                              <i className=" ri-building-3-line text-warning" />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-3 col-md-6">
                    {/* card */}
                    <div className="card card-animate">
                      <div className="card-body">
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 overflow-hidden">
                            <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                              Pending Projects
                            </p>
                          </div>
                          <div className="flex-shrink-0 d-none">
                            <h5 className="text-muted fs-14 mb-0">+0.00 %</h5>
                          </div>
                        </div>
                        <div className="d-flex align-items-end justify-content-between mt-4">
                          <div>
                            <h4 className="fs-22 fw-semibold ff-secondary mb-4">

                              <span
                                className="counter-value"
                                data-target="165.89"
                              >
                                20
                              </span>

                            </h4>
                            <Link
                              // to={`/TotalOrders/${3}`}
                              className="text-decoration-underline text-muted"
                            >
                              See All Pending Projects
                            </Link>
                          </div>
                          <div className="avatar-sm flex-shrink-0">
                            <span className="avatar-title bg-danger-subtle rounded fs-3">
                              <i
                                className=" bx bx-hourglass  text-danger"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                </div>{" "}
                {/* end row*/}
                <div className="row">
                  <div className="col-xl-8">
                    <div className="card">

                      {/* end card header */}
                      <StatusChart />
                      {/* end card body */}
                    </div>
                    {/* end card */}
                  </div>
                  {/* end col */}
                  <div className="col-xl-4">
                    <div className="card">
                      <DonutChart />
                    </div>
                  </div>
                  {/* end col */}
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {/* <BestSellingProducts /> */}
                  </div>
                  <div className="col-md-6">
                  </div>
                </div>
                {/* end row*/}
                {/* <ContractorProjectList /> */}
                {/* end row*/}
              </div>{" "}
              {/* end .h-100*/}
            </div>{" "}
            {/* end col */}

            {/* end col */}
          </div>
        </div>
        {/* container-fluid */}
      </div>
      {/* End Page-content */}
    </div>
  );
};

export default Dashboard;
