import React, { useContext } from "react";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { ConfigContext } from "../../Context/ConfigContext";
import axios from "axios";
import { Link } from "react-router-dom";

const DonutChart = () => {
  const [data, setData] = useState([
    {
      company_name: 'Builders Bae',
      client_name: 'Al Akram',
      contact_details: 'dualsysco@gmail.com / 98909-18835',
      location: 'Dubai, UAE',
      project_status: 'Not Assigned',
      batch_status: 'warning',
      image_url: 'assets/images/users/flat-design.jpg'
    },
    {
      company_name: 'Tech Hub',
      client_name: 'John Doe',
      contact_details: 'johndoe@example.com / 12345-67890',
      location: 'New York, USA',
      project_status: 'Assigned',
      batch_status: 'info',
      image_url: 'assets/images/users/1.jpg'
    },
    {
      company_name: 'Innovate Ltd.',
      client_name: 'Jane Smith',
      contact_details: 'janesmith@example.com / 09876-54321',
      location: 'London, UK',
      project_status: 'Completed',
      batch_status: 'success',
      image_url: 'assets/images/users/2.jpg'
    }
    
  ]);
  const { apiURL, apiHeaderJson } = useContext(ConfigContext);

  

  return (
    <>
      <div className="card-header align-items-center d-flex">
        <h4 className="card-title mb-0 flex-grow-1">Active Project </h4>
      </div>


      <div
        data-simplebar="init"
        style={{ height: '80vh' }}
        className="simplebar-scrollable-y"
      >
        <div className="simplebar-wrapper" style={{ margin: 0 }}>
          <div className="simplebar-height-auto-observer-wrapper">
            <div className="simplebar-height-auto-observer" />
          </div>
          <div className="simplebar-mask">
            <div className="simplebar-offset" style={{ right: 0, bottom: 0 }}>
              <div
                className="simplebar-content-wrapper"
                tabIndex={0}
                role="region"
                aria-label="scrollable content"
                style={{ height: "auto", overflow: "hidden scroll" }}
              >
                <div className="simplebar-content" style={{ padding: 0 }}>
                  {data.length !== 0 ? (
                    data.map((item, index) => (
                      <div className={`list-group ${index === 0 ? '' : ' mt-3'} px-3`} key={index}>
                        <Link to="/ProjectProfile" className="list-group-item list-group-item-action mt-3">
                          <div className="float-end">
                            <span className={`badge bg-${item.batch_status}-subtle text-${item.batch_status}`}>
                              {item.project_status}
                            </span>
                          </div>
                          <div className="d-flex mb-2 align-items-center">
                            <div className="flex-shrink-0">
                              <img
                                src={item.image_url}
                                alt=""
                                className="avatar-sm rounded-circle"
                              />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h5 className="list-title fs-15 mb-1">{item.company_name}</h5>
                              <p className="list-text mb-0 fs-12">{item.client_name}</p>
                            </div>
                          </div>
                          <div className="row mb-3 mt-3">
                            <div className="col-8 col-md-8">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-user-2-fill" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Contacts :</p>
                                  <h6 className="text-truncate mb-0">
                                    <a href="#" className="fw-semibold">{item.contact_details}</a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                            <div className="col-4 col-md-4">
                              <div className="d-flex">
                                <div className="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div className="avatar-title bg-light rounded-circle fs-16 text-primary">
                                    <i className="ri-map-pin-line" />
                                  </div>
                                </div>
                                <div className="flex-grow-1 overflow-hidden">
                                  <p className="mb-1">Location :</p>
                                  <a href="#" className="fw-semibold">{item.location}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    ))
                  ) : (
                    <div className="d-flex justify-content-center align-items-center" style={{ height: '60vh' }}>
                      <h3 className="text-muted">No Data Found...!</h3>
                    </div>
                  )}
                </div>

              </div>
            </div>
          </div>
          <div
            className="simplebar-placeholder"
            style={{ width: 358, height: 303 }}
          />
        </div>
        <div
          className="simplebar-track simplebar-horizontal"
          style={{ visibility: "hidden" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{ width: 0, display: "none" }}
          />
        </div>
        <div
          className="simplebar-track simplebar-vertical"
          style={{ visibility: "visible" }}
        >
          <div
            className="simplebar-scrollbar"
            style={{
              height: 152,
              transform: "translate3d(0px, 0px, 0px)",
              display: "block"
            }}
          />
        </div>
      </div>

    </>
  );
};

export default DonutChart;
