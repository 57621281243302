import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { ConfigContext } from '../../Context/ConfigContext';

const GallerySlider = () => {
    const { project_id } = useParams();
    const [galleryData, setGalleryData] = useState([]);
    const [currentImage, setCurrentImage] = useState(0);
    const [fade, setFade] = useState(true);
    const [loadingList, setLoadingList] = useState(true);
    const { apiURL, apiHeaderFile,imageURL } = useContext(ConfigContext);

    const handleGetGallery = async () => {
        try {
            setLoadingList(true);
            const response = await axios.get(
                `${apiURL}api/project-media/project/${project_id}`,
                { headers: apiHeaderFile }
            );
            const { data, success } = response.data;
            if (success) {
                const imageFiles = data.filter(item => item.pm_type === 'IMAGE');
                console.log('imageFiles',imageFiles);
                
                setGalleryData(imageFiles);
            }
            setLoadingList(false);
        } catch (error) {
            console.error('Error fetching gallery data:', error);
            setLoadingList(false);
        }
    };

    useEffect(() => {
        handleGetGallery();
    }, []);

    useEffect(() => {
        if (galleryData.length > 0) {
            const timer = setTimeout(() => {
                setFade(false);
                setTimeout(() => {
                    setCurrentImage((prevImage) => (prevImage + 1) % galleryData.length);
                    setFade(true);
                }, 500);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [currentImage, galleryData]);

    const handleThumbnailClick = (index) => {
        setFade(false);
        setTimeout(() => {
            setCurrentImage(index);
            setFade(true);
        }, 100);
    };

    return (
        <div className="card-body">
            {loadingList ? (
                <div>Loading gallery...</div>
            ) : (
                <div className="row justify-content-center">
                    <div className="col-12">
                        <div className="gallery-box">
                            <div className="gallery-container">
                                {galleryData.length > 0 && (
                                    <div className={`slider ${fade ? 'fade-in' : 'fade-out'}`}>
                                        <a className="image-popup">
                                            <img
                                                className="gallery-img img-fluid mx-auto"
                                                src={imageURL+ galleryData[currentImage].pm_file_name}
                                                alt={galleryData[currentImage].pm_title || `Gallery Image ${currentImage + 1}`}
                                            />
                                            <div className="gallery-overlay">
                                                <h5 className="overlay-caption">
                                                    {galleryData[currentImage].pm_title || `Image ${currentImage + 1}`}
                                                </h5>
                                            </div>
                                        </a>
                                    </div>
                                )}
                            </div>
                            <div className="box-content mt-3">
                                <div className="row justify-content-center mt-3">
                                    <div className="col-10 thumbnail-list d-flex justify-content-center">
                                        {galleryData.map((img, index) => (
                                            <img
                                                key={img.project_media_id}
                                                src={imageURL + img.pm_file_name }
                                                alt={img.pm_title || `Thumbnail ${index + 1}`}
                                                className={`thumbnail-img ${currentImage === index ? 'active' : ''}`}
                                                onClick={() => handleThumbnailClick(index)}
                                            />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {galleryData.length > 1 && (
                <div className="controls">
                    <button
                        className="prev-btn"
                        onClick={() => setCurrentImage((currentImage - 1 + galleryData.length) % galleryData.length)}
                    >
                        &#10094;
                    </button>
                    <button
                        className="next-btn"
                        onClick={() => setCurrentImage((currentImage + 1) % galleryData.length)}
                    >
                        &#10095;
                    </button>
                </div>
            )}
        </div>
    );
};

export default GallerySlider;
