import React, { createContext, useState } from 'react';

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [apiURL] = useState('https://tms.atctransline.in/apis/');
  const [imageURL] = useState('https://tms.atctransline.in/apis/public/images/');
  const [documentURL] = useState('https://tms.atctransline.in/apis/public/documents/');
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user_id] = useState(localStorage.getItem("user_id"));
  const [user_profile_id] = useState(localStorage.getItem("user_profile_id"));
  const [apiHeaderJson] = useState({ 'Content-Type': "application/json", token: `${token}`, 'user_id': user_id })
  const [apiHeaderFile] = useState({ 'Content-Type': "multipart/form-data", token: `${token}`, 'user_id': user_id })

  const handleUpdateLogin = (data) => {
    setToken(data.token);
    localStorage.setItem('token',  data.token);
    localStorage.setItem('user_id', data.data.user_id);
    localStorage.setItem('user_profile_id', data.data.user_profile_id);
  }

  var vals = {
    documentURL,
    imageURL,
    apiURL,
    token,
    apiHeaderJson,
    apiHeaderFile,
    handleUpdateLogin,
    user_profile_id,
    user_id
  };
  return (
    <ConfigContext.Provider value={vals}>
      {children}
    </ConfigContext.Provider>
  );
};
