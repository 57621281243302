import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { ConfigContext } from "../Context/ConfigContext";
import { useParams } from "react-router-dom";

const RecentActivity = () => {
    const { project_id } = useParams();
    const [logData, setLogData] = useState([]);
    const { apiURL } = useContext(ConfigContext);

    const GetLog = async () => {
        try {
            const response = await axios.get(`${apiURL}api/GetProjectLogs`, { params: { project_id: project_id } });
            
            if (response.data.success) {
                setLogData(response.data.data); 
            } else {
                console.error("Failed to fetch logs:", response.data.message);
            }
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };

    useEffect(() => {
        GetLog();
    }, [project_id]);

    return (
        <div>
            <div className="row">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header align-items-center d-flex">
                            <h4 className="card-title mb-0 me-2">Recent Activity</h4>
                        </div>
                        <div className="card-body">
                            <div className="tab-content text-muted">
                                <div className="tab-pane active" id="today" role="tabpanel">
                                    <div className="profile-timeline">
                                        <div className="accordion accordion-flush">
                                            {
                                            logData.map((log, index) => (
                                                <div className="accordion-item border-0" key={index}>
                                                    <div className="accordion-header" id={`heading${index}`}>
                                                        <a
                                                            className="accordion-button p-2 shadow-none"
                                                            data-bs-toggle="collapse"
                                                            href={`#collapse${index}`}
                                                            aria-expanded="true"
                                                        >
                                                            <div className="d-flex">
                                                                <div className="flex-shrink-0">
                                                                    <div style={{marginLeft:"-5px"}} className="avatar-title bg-light text-primary rounded-circle">
                                                                        <i style={{padding:'0px 15px'}} className="ri-file-line" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-14 mb-1">{log.project_log_title}</h6>
                                                                    <small className="text-muted">
                                                                        {log.project_log_datetime} by {log.project_log_created_by}
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div
                                                        id={`collapse${index}`}
                                                        className="accordion-collapse collapse"
                                                        aria-labelledby={`heading${index}`}
                                                        data-bs-parent="#todayExample"
                                                    >
                                                        <div className="accordion-body ms-2 ps-5">
                                                            {log.project_log_value}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/*end accordion*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* end card body */}
                    </div>
                    {/* end card */}
                </div>
                {/* end col */}
            </div>
        </div>
    );
};

export default RecentActivity;